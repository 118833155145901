
import { defineComponent } from 'vue'
import Tag from './Tag.vue'
import { store } from '@/store'
import router from '@/router'
import { ProjectTags, ProjectCategories, ProjectCategory, ProjectTag, ProjectContexts, ProjectContext } from '@/store/project-types'

export default defineComponent({
  name: 'ProjectSearch',
  components: {
    Tag
  },
  data() {
    return {
      tags: ProjectTags,
      categories: ProjectCategories,
      contexts: ProjectContexts,
    }
  },
  mounted() {
    this.applyUrlFilters();
  },
  computed: {
    isMobile() {
      return store.state.isMobile;
    }
  },
  methods: {
    applyUrlFilters() {
      const tags = router.currentRoute.value.query.tags as string;
      if (tags) {
        const tagArray = tags.split(';').filter(tag => tag != '');
        store.commit('addProjectTags', tagArray);
      }
    },
    isContextEnabled(context: string): boolean {
      return store.state.selectedProjectContext == context as ProjectContext;
    },
    isCategoryEnabled(category: string): boolean {
      return store.state.selectedProjectCategory == category as ProjectCategory;
    },
    isTagEnabled(tag: string): boolean {
      return store.state.selectedProjectTags.includes(tag as ProjectTag);
    },
    topScrollIfMobile() {
      if (this.isMobile)
        window.scrollTo(0, 0);
    },
    toggleTag(tag: string) {
      const tagIndex = store.state.selectedProjectTags.indexOf(tag as ProjectTag);
      const isDisabled = tagIndex === -1;
      if (isDisabled) {
        store.commit('addProjectTag', tag);
      }
      else {
        store.commit('removeProjectTag', tagIndex);
      }
    },
    toggleCategory(category: string) {
      if (this.isCategoryEnabled(category)) {
        store.commit('clearSelectedProjectCategory');
      }
      else {
        store.commit('selectProjectCategory', category);
      }
    },
    toggleContext(context: string) {
      if (this.isContextEnabled(context)) {
        store.commit('clearSelectedProjectContext');
      }
      else {
        store.commit('selectProjectContext', context);
      }
    },
    close() {
      store.commit('setShowSearchMenu', false);
    }
  }
});
