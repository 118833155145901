
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tag',
  props: {
    name: String,
    enabled: Boolean,
    color: { type: String, value: 'white' }
  }
});
