
import { defineComponent } from 'vue'
import { store } from '@/store'
import Project from '@/components/Project.vue'
import ProjectSearch from '@/components/ProjectSearch.vue'
import ProjectsData from '@/assets/projects-data.json'
import { ProjectCategory, ProjectContext } from '@/store/project-types'

export default defineComponent({
  name: 'Projects',
  components: {
    Project,
    ProjectSearch
  },
  computed: {
    showSearchMenu() {
      return store.state.showSearchMenu;
    },
    projects() {
      let data = Object.values(ProjectsData);
      if (store.state.selectedProjectContext != ProjectContext.NONE)
        data = data.filter(project => store.state.selectedProjectContext == project.context as ProjectContext);

      if (store.state.selectedProjectCategory != ProjectCategory.NONE)
        data = data.filter(project => store.state.selectedProjectCategory == project.category as ProjectCategory);

      if (store.state.selectedProjectTags.length > 0)
        data = data.filter(project => store.state.selectedProjectTags.every((tag: string) => project.tags.includes(tag)));

      return data;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
});
